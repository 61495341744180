import { Group, type GroupProps, Stack, Text, ThemeIcon } from "@mantine/core";

import dayjs from "dayjs";
import { BiAddToQueue } from "react-icons/bi";

import classes from "./SilverColumn.module.css";
import type { SilverColumnDef } from "../utils";

export const SilverColumn = ({
  col: { output_column, created_at },
  ...props
}: {
  col: SilverColumnDef;
} & GroupProps) => {
  return (
    <Group p={"xs"} className={classes.group} {...props}>
      <Stack flex={1} gap={0}>
        <Text>{output_column}</Text>
        <Text c={"dimmed"} size="sm">{`created ${dayjs(created_at)
          .utc()
          .fromNow()}`}</Text>
      </Stack>
      <ThemeIcon className={classes.themeIcon} variant="transparent">
        <BiAddToQueue />
      </ThemeIcon>
    </Group>
  );
};
