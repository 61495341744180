import type { NodeTypes } from "@xyflow/react";
import { TemplateNode } from "./TemplateNode";

export const nodeTypes: NodeTypes = {
  source: (props) => <TemplateNode title={"Source"} {...props} />,
  subquery: (props) => <TemplateNode title={"Subquery"} {...props} />,
  filter: (props) => <TemplateNode title={"Filter"} {...props} />,
  grouping: (props) => <TemplateNode title={"Group"} {...props} />,
  join: (props) => <TemplateNode title={"Join"} {...props} />,
  calculate: (props) => <TemplateNode title={"Calculate"} {...props} />,
  sort: (props) => <TemplateNode title={"Sort"} {...props} />,
  outcome: (props) => <TemplateNode title={"Outcome"} {...props} />,
};
