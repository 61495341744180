import { useNavigate } from "react-router-dom";

import { ActionIcon, Group, Stack, Title, Tooltip } from "@mantine/core";

import { BiArrowToRight } from "react-icons/bi";

interface PageProps {
  title: string;
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({
  title,
  leftSection,
  rightSection,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      style={{ maxWidth: "var(--mantine-breakpoint-xl)" }}
      w="90%"
      py={20}
      h={"100vh"}
      gap={"xl"}
    >
      <Group justify="space-between" align="flex-start">
        <Group gap={"xs"} align="flex-start">
          <Tooltip label="Show menu" offset={8}>
            <ActionIcon
              onClick={() => navigate({ hash: "menu" })}
              variant={"subtle"}
              size={"md"}
            >
              <BiArrowToRight size={20} />
            </ActionIcon>
          </Tooltip>
          <Group align="center">
            <Title order={3}>{title}</Title>
            {leftSection}
          </Group>
        </Group>
        {rightSection}
      </Group>
      {children}
    </Stack>
  );
};
