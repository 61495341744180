import type { Simplify } from "type-fest";
import type { Database } from "@mm/shared/schemas/supabase/database.types";
import type { ProcessStatus } from "./schemas/ProcessStatus";
import { z } from "zod";

export type Concept = {
  id: number;
  concept: string;
  conceptId: number;
  definition: string | null;
  explanation: string;
  existing: boolean;
};

export type AssistantTable = Database["public"]["Tables"]["assistants"]["Row"];
export type ThreadTable = Database["public"]["Tables"]["threads"]["Row"];
export type Thread = Simplify<
  Omit<ThreadTable, "process_status"> & {
    process_status?: ProcessStatus;
  }
>;

export interface Feedback {
  general: string;
  columns: Record<string, string>;
}

export type FeedbackType = "general" | "column";

export const ChatOptionsParser = z
  .object({
    suggestion: z.string().optional(),
    explanation: z.string(),
    label: z.string(),
  })
  .array();

export type ChatOptions = z.output<typeof ChatOptionsParser>;

export const messagesInputSchema = z.object({
  messages: z
    .object({
      role: z.enum(["user", "system", "assistant"]).default("user"),
      content: z.string().nullable(),
    })
    .array(),
  model: z.string().default("llama3-8b-8192"),
  json: z.boolean().default(true),
});

export type PromptInput = z.output<typeof messagesInputSchema>;

// Verify results types
const Node = z.object({
  id: z.string(),
  type: z.enum([
    "source",
    "subquery",
    "filter",
    "grouping",
    "join",
    "calculate",
    "sort",
    "outcome",
  ]),
  explanation: z.string(),
});

const Edge = z.object({
  source: z.string(),
  target: z.string(),
});

export const LLMVerifyResultsResponse = z.object({
  summary: z.string(),
  businessEntities: z.array(z.string()),
  metrics: z.array(z.string()),
  dataTransformations: z.array(z.string()),
  filters: z.array(z.string()),
  timeAspect: z.string().optional(),
  dataStructureRelevance: z.string(),
  comparisonToOriginalRequest: z.array(z.string()),
  queryDetails: z.array(
    z.object({
      chunkTitle: z.string(),
      chunkExplanation: z.string(),
      lines: z.array(
        z.object({
          sql: z.string(),
          explanation: z.string(),
        }),
      ),
    }),
  ),
  queryDataFlow: z.object({
    nodes: z.array(Node),
    edges: z.array(Edge),
  }),
});

export type LLMVerifyResultsResponseType = z.infer<
  typeof LLMVerifyResultsResponse
>;
