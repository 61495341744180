import { renderDbElements } from "@components/Markdown/DbElement";
import { useClient } from "@hooks/use-client";
import {
  Box,
  Center,
  Drawer,
  List,
  Loader,
  Stack,
  Tabs,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import {
  LLMVerifyResultsResponse,
  type LLMVerifyResultsResponseType,
} from "@mm/shared/companion/types";
import { useQuery } from "@tanstack/react-query";
import type { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import { useResults } from "../ResultsContext";
import { QueryDataFlow } from "./QueryDataFlow";
import QueryDetails from "./QueryDetails";

const SECTION_DESCRIPTIONS = {
  "Business Entities":
    "Key business elements involved in the query, such as departments, employees, or products.",
  Metrics:
    "Quantitative measures or calculations performed in the query, like averages, counts, or rates.",
  "Changes from Original Request":
    "Differences between the initial request and the final query results, including any adjustments made.",
  "Data Structure Relevance":
    "How the query relates to the available database structure, tables, and relationships.",
  "Data Transformations":
    "Significant data manipulations or calculations performed within the query.",
  Filters:
    "Specific conditions or criteria applied to narrow down the data in the query.",
  "Time Aspect":
    "Temporal considerations in the query, such as date ranges or time-based calculations.",
} as const;

interface VerifyResultsDrawerProps {
  opened: boolean;
  close: () => void;
}

const useVerifyResults = (opened: boolean, goldViewId: number) => {
  const { fetchAPIWithToken } = useClient();
  return useQuery({
    queryKey: ["verifyResults", goldViewId],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${goldViewId}/verify`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      return LLMVerifyResultsResponse.parse(await response.json());
    },
    enabled: opened,
  });
};

const ExplanationSection: React.FC<{
  title: keyof typeof SECTION_DESCRIPTIONS;
  content: string | string[];
}> = ({ title, content }) => {
  return (
    <Box p={"sm"}>
      <Text fw={"bold"}>{title}</Text>
      <Text mb="sm" c={"dimmed"} size="sm">
        {SECTION_DESCRIPTIONS[title]}
      </Text>
      {Array.isArray(content) ? (
        content.length > 0 ? (
          <List spacing={"xs"}>
            {content.map((item, index) => (
              <List.Item key={index}>
                <TypographyStylesProvider fz="sm">
                  <ReactMarkdown components={{ code: renderDbElements }}>
                    {item}
                  </ReactMarkdown>
                </TypographyStylesProvider>
              </List.Item>
            ))}
          </List>
        ) : (
          <Text size="sm">None.</Text>
        )
      ) : (
        <TypographyStylesProvider fz="sm">
          <ReactMarkdown components={{ code: renderDbElements }}>
            {content ? content : "None."}
          </ReactMarkdown>
        </TypographyStylesProvider>
      )}
    </Box>
  );
};

const Layout = ({
  opened,
  children,
  close,
}: VerifyResultsDrawerProps & {
  children: ReactNode;
}) => {
  return (
    <Drawer.Root
      position="right"
      size={"100%"}
      offset={8}
      radius="md"
      opened={opened}
      onClose={close}
    >
      <Drawer.Overlay />
      <Drawer.Content
        display={"flex"}
        style={{ flexDirection: "column", alignItems: "stretch" }}
      >
        <Drawer.Header>
          <Title order={4}>Results verification</Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body
          flex={1}
          display={"flex"}
          style={{ flexDirection: "column" }}
        >
          {children}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

const Loading = () => {
  return (
    <Center flex={1}>
      <Stack align="center">
        <Loader />
        <Text c={"dimmed"} size="sm">
          Drilling down your results&hellip;
        </Text>
      </Stack>
    </Center>
  );
};

const ShowResults = ({ data }: { data: LLMVerifyResultsResponseType }) => {
  return (
    <>
      <Text mb={"lg"}>{data.summary}</Text>
      <Tabs
        flex={1}
        defaultValue="Query Insights"
        variant="outline"
        display={"flex"}
        style={{ flexDirection: "column" }}
      >
        <Tabs.List>
          <Tabs.Tab value="Query Insights">Query Insights</Tabs.Tab>
          <Tabs.Tab value="Query Data Flow">Query Data Flow</Tabs.Tab>
          <Tabs.Tab value="Query Details">Query Details</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel flex={1} p={"sm"} value="Query Insights">
          <Stack>
            <ExplanationSection
              title="Business Entities"
              content={data.businessEntities}
            />
            <ExplanationSection title="Metrics" content={data.metrics} />
            <ExplanationSection
              title="Changes from Original Request"
              content={data.comparisonToOriginalRequest}
            />
            <ExplanationSection
              title="Data Structure Relevance"
              content={data.dataStructureRelevance}
            />
            <ExplanationSection
              title="Data Transformations"
              content={data.dataTransformations}
            />
            {!!data.filters && (
              <ExplanationSection title="Filters" content={data.filters} />
            )}
            {!!data.timeAspect && (
              <ExplanationSection
                title="Time Aspect"
                content={data.timeAspect}
              />
            )}
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel flex={1} p={"sm"} value="Query Data Flow">
          <QueryDataFlow queryDataFlow={data.queryDataFlow} />
        </Tabs.Panel>
        <Tabs.Panel flex={1} p={"sm"} value="Query Details">
          <QueryDetails queryDetails={data.queryDetails} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export const VerifyResultsDrawer: React.FC<VerifyResultsDrawerProps> = ({
  opened,
  close,
}) => {
  const { goldView } = useResults();
  const { data, isPending } = useVerifyResults(opened, goldView.id);

  return (
    <Layout opened={opened} close={close}>
      {isPending || !data ? <Loading /> : <ShowResults data={data} />}
    </Layout>
  );
};
