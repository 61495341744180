import React, { useMemo } from "react";
import {
  Accordion,
  ActionIcon,
  Button,
  Modal,
  Stack,
  Text,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import { BiTrash } from "react-icons/bi";
import { useDataBuilder } from "../DataBuilderContext";
import type { SilverColumnDef } from "../utils";
import type { Feedback, FeedbackType } from "@mm/shared/companion/types";

interface FeedbackModalProps {
  opened: boolean;
  close: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  opened,
  close,
}) => {
  const { selectedColumns, feedback, setFeedback } = useDataBuilder();

  const handleFeedbackChange = (
    type: FeedbackType,
    id: number | null,
    value: string,
  ) => {
    setFeedback((prev: Feedback) => {
      if (type === "general") {
        return { ...prev, general: value };
      } else {
        return { ...prev, columns: { ...prev.columns, [id!]: value } };
      }
    });
  };

  const handleSubmitFeedback = () => {
    // TODO: Implement feedback submission logic
    console.log("Feedback submitted:", feedback);
    close();
  };

  const isFeedbackEmpty = useMemo(() => {
    return (
      feedback.general.trim() === "" &&
      Object.values(feedback.columns).every((value) => value.trim() === "")
    );
  }, [feedback]);

  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title="Refine AI-generated results"
      size="lg"
      padding="lg"
      styles={{ title: { fontWeight: "bold" } }}
    >
      <Stack>
        <Text size="sm" color="dimmed">
          Your feedback will be used to refine the AI-generated results. We'll
          rerun the analysis with your input to improve the results.
        </Text>
        <Title order={5}>Overall report feedback</Title>
        <Textarea
          variant="filled"
          placeholder="Enter your feedback about the overall table ..."
          value={feedback.general}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleFeedbackChange("general", null, e.target.value)
          }
        />
        <Title order={5}>Column-specific feedbacks</Title>
        <Accordion variant="separated" chevronPosition="left">
          {selectedColumns.map((column: SilverColumnDef) => (
            <Accordion.Item key={column.id} value={column.output_column}>
              <Accordion.Control
                icon={
                  !!feedback.columns[column.id] && (
                    <Tooltip label="Clear feedback">
                      <ActionIcon
                        component="a"
                        size="lg"
                        variant="subtle"
                        color="gray"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleFeedbackChange("column", column.id, "");
                        }}
                      >
                        <BiTrash size={16} />
                      </ActionIcon>
                    </Tooltip>
                  )
                }
              >
                {column.output_column}
              </Accordion.Control>
              <Accordion.Panel>
                <Textarea
                  variant="filled"
                  placeholder="Enter your feedback about this column ..."
                  value={feedback.columns[column.id] || ""}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleFeedbackChange("column", column.id, e.target.value)
                  }
                />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
        <Button
          onClick={handleSubmitFeedback}
          disabled={isFeedbackEmpty}
          mt={16}
        >
          Rerun analysis
        </Button>
      </Stack>
    </Modal>
  );
};
