import { useClient } from "@hooks/use-client";
import {
  useQueries,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import React, { createContext, useContext, useState } from "react";
import type { GoldViewDef } from "./utils";
import { z } from "zod";
import type { Feedback, Thread } from "@mm/shared/companion/types";

const ColumnsResultParser = z.object({
  data: z.object({ name: z.string() }).array(),
});

const TableResultDataParser = z.object({ data: z.record(z.unknown()).array() });

interface ResultsColumnsData {
  data: { name: string; isNew: boolean }[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null | undefined;
}

interface ResultsContextType {
  thread: Thread;
  feedback: Feedback;
  setFeedback: React.Dispatch<React.SetStateAction<Feedback>>;
  goldView: GoldViewDef;
  lastSuccessGoldView: GoldViewDef | undefined;
  useResultsColumns: ResultsColumnsData;
  useResultsData: UseQueryResult<Record<string, unknown>[], Error>;
}

const ResultsContext = createContext<ResultsContextType | undefined>(undefined);

interface ResultsProviderProps {
  goldView: GoldViewDef;
  thread: Thread;
  lastSuccessGoldView: GoldViewDef | undefined;
  children: React.ReactNode;
}

export const ResultsContextProvider: React.FC<ResultsProviderProps> = ({
  goldView,
  thread,
  lastSuccessGoldView,
  children,
}) => {
  const { fetchAPIWithToken } = useClient();

  /*
   * We store the feedback in the context that way it's not tied to the state
   * of the modal.
   * If the modal is closed the state would be persisted (i.e not lost)
   */
  const [feedback, setFeedback] = useState<Feedback>({
    general: "",
    columns: {},
  });

  const lastViewId = lastSuccessGoldView?.id;
  const useResultsColumns: ResultsColumnsData = useQueries({
    queries: [
      {
        queryKey: ["newColumns", lastViewId],
        queryFn: async () => {
          const response = await fetchAPIWithToken(
            `/api/gold/views/${lastViewId}/columns?intermediate`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            },
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch new columns for gold view ${lastViewId}`,
            );
          }

          const { data } = ColumnsResultParser.parse(await response.json());
          return data.map(({ name }) => name);
        },
        enabled: !!lastViewId,
      },
      {
        queryKey: ["resultsColumns", lastViewId],
        queryFn: async () => {
          const response = await fetchAPIWithToken(
            `/api/gold/views/${lastViewId}/columns`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
            },
          );

          if (!response.ok) {
            throw new Error(
              `Failed to fetch results columns for gold view ${lastViewId}`,
            );
          }

          const { data } = ColumnsResultParser.parse(await response.json());
          return data.map(({ name }) => name);
        },
        enabled: !!lastViewId,
      },
    ],
    combine: (results): ResultsColumnsData => {
      const [newColumnsResult, resultsColumnsResult] = results;
      const newColumns = newColumnsResult.data || [];
      const allColumns = resultsColumnsResult.data || [];
      return {
        data: allColumns.map((name) => ({
          name,
          isNew: newColumns.includes(name),
        })),
        isLoading: results.some((result) => result.isLoading),
        isError: results.some((result) => result.isError),
        error: results.find((result) => result.error)?.error,
      };
    },
  });

  const useResultsData = useQuery({
    enabled: !!lastViewId,
    queryKey: ["resultsData", lastViewId],
    queryFn: async () => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${lastViewId}/data`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch data for gold view ${lastViewId}`);
      }

      const { data } = TableResultDataParser.parse(await response.json());
      return data;
    },
  });

  const value: ResultsContextType = {
    thread,
    feedback,
    setFeedback,
    goldView,
    lastSuccessGoldView,
    useResultsColumns,
    useResultsData,
  };

  return (
    <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>
  );
};

export const useResults = (): ResultsContextType => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error("useResults must be used within a ResultsContextProvider");
  }
  return context;
};
