import {
  DataBuilderProvider,
  useDataBuilder,
} from "@components/DataBuilder/DataBuilderContext";
import { FeedbackModal } from "@components/DataBuilder/FeedbackModal";
import { Table } from "@components/DataBuilder/Table";
import { Page } from "@layout/Page";
import { Button, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { BiAnalyse, BiExport } from "react-icons/bi";

export const DataBuilder: React.FC = () => {
  return (
    <DataBuilderProvider>
      <DataBuilderContent />
    </DataBuilderProvider>
  );
};

const DataBuilderContent: React.FC = () => {
  const { selectedColumns, handleExport, isExporting } = useDataBuilder();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <Page
      title="Insight Data Builder"
      rightSection={
        <Group gap={"xs"} ta={"right"}>
          <Text c={"dimmed"} size="xs">
            {selectedColumns.length} columns selected
          </Text>
          <Button
            size="xs"
            variant="light"
            leftSection={<BiAnalyse size={14} />}
            disabled={selectedColumns.length === 0}
            onClick={open}
          >
            Improve results
          </Button>
          <Button
            size="xs"
            variant="filled"
            leftSection={<BiExport size={14} />}
            disabled={selectedColumns.length === 0}
            onClick={handleExport}
            loading={isExporting}
          >
            Export
          </Button>
        </Group>
      }
    >
      <Table />
      <FeedbackModal opened={opened} close={close} />
    </Page>
  );
};
