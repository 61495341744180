/**
 * This type is manually written to match the API from the backend
 * we can remove than when merging the repo
 */
export type SilverColumnDef = {
  id: number;
  input_table: string;
  output_column: string;
  created_at: string;
  subheader?: string;
  source: {
    name: string;
    tables: {
      table_name: string;
      display_table_name: string;
    }[];
  };
};

export const getDisplayTableName = (col: SilverColumnDef) => {
  const table = col.source.tables.find(
    ({ table_name }) => table_name === col.input_table,
  );

  return table ? table.display_table_name : col.input_table;
};

export type SourceName = string;
export type TableName = string;

export const groupBySourceAndTableDisplayName = (cols: SilverColumnDef[]) => {
  // First, group by source name
  const groupedBySource = Object.groupBy(cols, (col) => col.source.name);
  const result: Record<SourceName, Record<TableName, SilverColumnDef[]>> = {};

  // Then, for each source group, group by table display name
  for (const [sourceName, sourceCols] of Object.entries(groupedBySource)) {
    if (sourceCols) {
      result[sourceName] = Object.groupBy(
        sourceCols,
        getDisplayTableName,
      ) as Record<TableName, SilverColumnDef[]>;
    }
  }

  return result;
};
