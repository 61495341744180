import React from "react";
import { Center, Loader, Stack, Text } from "@mantine/core";
import { SilverColumn } from "./SilverColumn";
import { useSilverColumns } from "./useSilverColumns";
import { useDataBuilder } from "../DataBuilderContext";
import type { SilverColumnDef } from "../utils";

interface AddModalProps {
  close: () => void;
}

export const AddModal: React.FC<AddModalProps> = ({ close }) => {
  const { selectedColumns, addSelectedColumn } = useDataBuilder();

  const availableTables = new Set(
    selectedColumns.map((col) => col.input_table),
  );

  const { data: silverColumns, isPending } = useSilverColumns(
    availableTables,
    selectedColumns,
  );

  type GroupedColumns = [string, Record<string, SilverColumnDef[]>][];
  const groupedColumns: GroupedColumns = silverColumns
    ? Object.entries(silverColumns)
    : [];

  const handleColumnSelect = (column: SilverColumnDef) => {
    addSelectedColumn(column);
    close();
  };

  if (isPending) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (groupedColumns.length === 0) {
    return <Text c="dimmed">No additional columns available.</Text>;
  }

  return (
    <Stack>
      {groupedColumns.map(([sourceName, tables]) => (
        <Stack key={sourceName} gap="md">
          <Text fw="bold">{sourceName}</Text>
          {Object.entries(tables).map(([tableDisplayName, columns]) => (
            <Stack key={`${sourceName}-${tableDisplayName}`} gap="xs">
              <Text fw="semibold">{tableDisplayName}</Text>
              <Stack>
                {columns.map((col) => (
                  <SilverColumn
                    key={col.id}
                    onClick={() => handleColumnSelect(col)}
                    col={col}
                  />
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
