import {
  Accordion,
  ActionIcon,
  Badge,
  Box,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  Title,
  Tooltip,
} from "@mantine/core";
import type { Feedback, FeedbackType } from "@mm/shared/companion/types";
import React, { useMemo } from "react";
import { BiTrash } from "react-icons/bi";
import { useResults } from "../ResultsContext";
import { useText2SqlFeedback } from "./hooks";

interface FeedbackModalProps {
  opened: boolean;
  close: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  opened,
  close,
}) => {
  const {
    useResultsColumns: { data: columnsData = [] },
    feedback,
    goldView,
    setFeedback,
  } = useResults();

  const handleFeedbackChange = (
    type: FeedbackType,
    columnName: string | null,
    value: string,
  ) => {
    setFeedback((prev: Feedback) => {
      if (type === "general") {
        return { ...prev, general: value };
      } else {
        return { ...prev, columns: { ...prev.columns, [columnName!]: value } };
      }
    });
  };

  const { mutate: sendFeedback } = useText2SqlFeedback(goldView);

  const handleSubmitFeedback = () => {
    sendFeedback(feedback);
    close();
  };

  const isFeedbackEmpty = useMemo(() => {
    return (
      feedback.general.trim() === "" &&
      Object.values(feedback.columns).every((value) => value.trim() === "")
    );
  }, [feedback]);

  return (
    <Modal
      centered
      opened={opened}
      onClose={close}
      title="Refine AI-generated results"
      size="lg"
      padding="lg"
      styles={{ title: { fontWeight: "bold" } }}
    >
      <Stack>
        <Text size="sm" color="dimmed">
          Your feedback will be used to refine the AI-generated results. We'll
          rerun the analysis with your input to improve the results.
        </Text>
        <Title order={5}>Overall results feedback</Title>
        <Textarea
          variant="filled"
          placeholder="Enter your feedback about the overall table ..."
          value={feedback.general}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleFeedbackChange("general", null, e.target.value)
          }
        />
        <Title order={5}>Column-specific feedbacks</Title>
        <Accordion variant="separated" chevronPosition="left">
          {columnsData.map(({ name, isNew }) => (
            <Accordion.Item key={name} value={name}>
              <Accordion.Control
                icon={
                  !!feedback.columns[name] && (
                    <Tooltip label="Clear feedback">
                      <ActionIcon
                        component="a"
                        size="lg"
                        variant="subtle"
                        color="gray"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleFeedbackChange("column", name, "");
                        }}
                      >
                        <BiTrash size={16} />
                      </ActionIcon>
                    </Tooltip>
                  )
                }
              >
                <Group gap={"xs"} style={{ flexWrap: "nowrap" }}>
                  <Tooltip multiline maw={"25rem"} label={name}>
                    <Text truncate="end">{name}</Text>
                  </Tooltip>
                  {isNew && (
                    <Tooltip
                      label={"New data column created by your companion"}
                    >
                      <Box flex={0}>
                        <Badge
                          size="sm"
                          display={"flex"}
                          variant="dot"
                          color="green"
                          c={"dimmed"}
                        >
                          new
                        </Badge>
                      </Box>
                    </Tooltip>
                  )}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Textarea
                  variant="filled"
                  placeholder="Enter your feedback about this column ..."
                  value={feedback.columns[name] || ""}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleFeedbackChange("column", name, e.target.value)
                  }
                />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
        <Button
          onClick={handleSubmitFeedback}
          disabled={isFeedbackEmpty}
          mt={16}
        >
          Rerun analysis
        </Button>
      </Stack>
    </Modal>
  );
};
