import React, { forwardRef } from "react";
import {
  ActionIcon,
  Box,
  Center,
  Loader,
  Stack,
  Text,
  Tooltip,
  Transition,
  type StackProps,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { BiEditAlt, BiTrash } from "react-icons/bi";
import classes from "./Column.module.css";
import { useDataBuilder } from "./DataBuilderContext";
import type { SilverColumnDef } from "./utils";

interface WrapperColumnProps extends StackProps {
  children: React.ReactNode;
}

export const WrapperColumn = forwardRef<HTMLDivElement, WrapperColumnProps>(
  ({ children, ...props }, ref) => {
    return (
      <Stack
        ref={ref}
        {...props}
        className={[props.className, classes.column].filter(Boolean).join(" ")}
        p="sm"
        miw={0}
      >
        {children}
      </Stack>
    );
  },
);

const EmptyCell: React.FC = () => (
  <Text span c="dimmed" size="sm">
    empty
  </Text>
);

interface ColumnProps {
  column: SilverColumnDef;
}

export const Column: React.FC<ColumnProps> = ({ column }) => {
  const { removeSelectedColumn, useColumnData } = useDataBuilder();
  const { hovered, ref } = useHover();
  const { data, isPending } = useColumnData(column);

  return (
    <WrapperColumn className={classes.standardColumn} ref={ref}>
      <Box>
        <Stack gap={0}>
          <Tooltip multiline maw={"25rem"} label={column.output_column}>
            <Text fw="bold" truncate="end">
              {column.output_column}
            </Text>
          </Tooltip>
          <Tooltip multiline maw={"25rem"} label={column.subheader}>
            <Text c="dimmed" size="sm" truncate="end">
              {column.subheader}
            </Text>
          </Tooltip>
        </Stack>
      </Box>
      <Stack
        flex={1}
        style={{
          flex: 1,
          minHeight: 0,
          overflow: "clip",
          position: "relative",
        }}
        className={classes.fadeStack}
      >
        <Transition
          keepMounted
          mounted={hovered}
          transition="fade"
          duration={200}
          timingFunction="ease"
        >
          {(styles) => (
            <Box
              style={{
                ...styles,
                position: "absolute",
                width: "100%",
                bottom: 0,
                zIndex: 2,
              }}
            >
              <Center h="100%">
                <ActionIcon.Group>
                  <Tooltip label="Rename column name">
                    <ActionIcon size="lg" variant="subtle">
                      <BiEditAlt size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Remove column">
                    <ActionIcon
                      size="lg"
                      variant="subtle"
                      color="red"
                      onClick={() => removeSelectedColumn(column)}
                    >
                      <BiTrash size={20} />
                    </ActionIcon>
                  </Tooltip>
                </ActionIcon.Group>
              </Center>
            </Box>
          )}
        </Transition>
        {isPending ? (
          <Center flex={1}>
            <Loader />
          </Center>
        ) : (
          data?.map((value, index) => (
            <Box key={index}>
              {value === undefined || value === null || value === "" ? (
                <EmptyCell />
              ) : (
                <Tooltip multiline maw={"25rem"} label={value}>
                  <Text truncate="end">{value}</Text>
                </Tooltip>
              )}
            </Box>
          ))
        )}
      </Stack>
    </WrapperColumn>
  );
};
