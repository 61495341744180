import { useCallback } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useClient } from "@hooks/use-client";

import {
  groupBySourceAndTableDisplayName,
  type SilverColumnDef,
} from "../utils";

export const useSilverColumns = (
  availableTables: Set<string>,
  selectedColumns: SilverColumnDef[],
) => {
  const { fetchAPIWithToken } = useClient();

  const filterAndGroupColumns = useCallback(
    (columns: SilverColumnDef[]) => {
      const existingSet = new Set(
        selectedColumns.map((col) => `${col.source.name}:${col.output_column}`),
      );

      const filteredColumns = columns.filter(
        (col) => !existingSet.has(`${col.source.name}:${col.output_column}`),
      );

      return groupBySourceAndTableDisplayName(filteredColumns);
    },
    [selectedColumns],
  );

  const hasMoreThanOneTable = availableTables.size > 0;

  const singleQuery = useQuery({
    queryKey: ["allSilverColumns"],
    queryFn: async () => {
      const response = await fetchAPIWithToken(`/api/silver/columns`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch columns`);
      }

      const { columns } = await response.json();

      return columns;
    },
    select: filterAndGroupColumns,
    enabled: !hasMoreThanOneTable,
  });

  const otherQueries = useQueries({
    queries: [...availableTables].map((tableName) => ({
      queryKey: ["tableColumns", tableName],
      queryFn: async () => {
        const response = await fetchAPIWithToken(
          `/api/silver/columns?tableName=${tableName}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          },
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch columns for table ${tableName}`);
        }

        const { columns } = await response.json();
        return columns;
      },
    })),
    combine: (results) => {
      const isPending = results.some((result) => result.isPending);
      const allColumns = results
        .flatMap((result) => result.data)
        .filter((data) => data !== undefined);

      const groupedData = filterAndGroupColumns(allColumns);

      return {
        data: groupedData,
        isPending: isPending,
      };
    },
  });

  if (hasMoreThanOneTable) return otherQueries;
  return singleQuery;
};
