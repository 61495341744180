import React from "react";
import { Center, Image, Modal, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import AddImage from "@images/add-column.svg";
import { AddModal } from "./AddModal";
import { WrapperColumn } from "./Column";
import classes from "./Column.module.css";

export const AddColumn: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Select silver column"
        size="lg"
        centered
        styles={{ title: { fontWeight: "bold" } }}
      >
        <AddModal close={close} />
      </Modal>
      <WrapperColumn className={classes.addColumn} onClick={open}>
        <Center flex={1}>
          <Stack align="center">
            <Image m="auto" w="50%" src={AddImage} />
            <Text size="md">Add column</Text>
          </Stack>
        </Center>
      </WrapperColumn>
    </>
  );
};
