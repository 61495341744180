import { useHover } from "@mantine/hooks";
import { useState } from "react";

import { Badge, Card, Center, Loader, Stack, Text } from "@mantine/core";
import classes from "./Recommendation.module.css";

export const Recommendation = ({
  createThread,
  starter,
  explanation,
  type,
  ...props
}: {
  createThread: (starter: string) => Promise<void>;
  starter: string;
  explanation: string;
  type: string;
}) => {
  const { hovered, ref: hoveredRef } = useHover();
  const [loading, setLoading] = useState(false);

  return (
    <Card
      ref={hoveredRef}
      flex={1}
      onClick={() => {
        setLoading(true);
        void createThread(`${starter}.\n\n${explanation}`);
      }}
      className={classes.item}
      withBorder
      p={"sm"}
      {...props}
    >
      {loading ? (
        <Center h={"100%"}>
          <Loader />
        </Center>
      ) : (
        <>
          <Stack gap={"xs"} flex={1}>
            <Badge
              variant="light"
              size="xs"
              color={
                !hovered
                  ? "var(--mantine-color-gray-5)"
                  : "var(--mantine-primary-color-6)"
              }
            >
              {type}
            </Badge>
            <Text size="sm">{starter}</Text>
          </Stack>
          <Text size="xs" c="dimmed" mt={8}>
            {explanation}
          </Text>
        </>
      )}
    </Card>
  );
};
