import React, { useMemo } from "react";
import { Card, Group, ScrollArea } from "@mantine/core";
import { Column } from "./Column";
import { AddColumn } from "./AddColumn";
import { useDataBuilder } from "./DataBuilderContext";
import { getDisplayTableName, type SilverColumnDef } from "./utils";
import classes from "./Table.module.css";

export const Table: React.FC = () => {
  const { selectedColumns } = useDataBuilder();

  const allColumns = useMemo<SilverColumnDef[]>(() => {
    return selectedColumns
      .map((column) => ({
        ...column,
        subheader: `${column.source.name} → ${getDisplayTableName(column)}`,
      }))
      .sort((a, b) => {
        if (a.source.name !== b.source.name) {
          return a.source.name.localeCompare(b.source.name);
        }
        return getDisplayTableName(a).localeCompare(getDisplayTableName(b));
      });
  }, [selectedColumns]);

  return (
    <Card flex={1} radius="lg">
      <ScrollArea.Autosize
        flex={1}
        scrollbars="x"
        styles={{
          root: { display: "flex", flex: 1 },
          viewport: { display: "flex" },
        }}
        classNames={classes}
      >
        <Group flex={1} gap="xs" align="stretch" wrap="nowrap">
          {allColumns.map((column) => (
            <Column key={column.id} column={column} />
          ))}
          <AddColumn />
        </Group>
      </ScrollArea.Autosize>
    </Card>
  );
};
