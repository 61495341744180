import React, { createContext, useContext, useState, useCallback } from "react";
import {
  useMutation,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { useClient } from "@hooks/use-client";
import type { SilverColumnDef } from "./utils";
import type { Feedback } from "@mm/shared/companion/types";

interface ExportMutationVariables {
  tableName: string;
  columnIds: number[];
}

interface ExportMutationResult {
  success: boolean;
}

interface DataBuilderContextType {
  selectedColumns: SilverColumnDef[];
  addSelectedColumn: (newColumn: SilverColumnDef) => void;
  removeSelectedColumn: (columnToRemove: SilverColumnDef) => void;
  feedback: Feedback;
  setFeedback: React.Dispatch<React.SetStateAction<Feedback>>;
  handleExport: () => void;
  isExporting: boolean;
  useColumnData: (column: SilverColumnDef) => UseQueryResult<string[], Error>;
}

const DataBuilderContext = createContext<DataBuilderContextType | undefined>(
  undefined,
);

const downloadBlob = async (res: Response) => {
  const contentDisposition = res.headers.get("Content-Disposition");
  let filename = "download.csv";

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/i);
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1];
    }
  }

  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.download = filename;
  fileLink.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(fileURL);
  }, 100);
};

interface DataBuilderProviderProps {
  children: React.ReactNode;
}

export const DataBuilderProvider: React.FC<DataBuilderProviderProps> = ({
  children,
}) => {
  const [selectedColumns, setSelectedColumns] = useState<SilverColumnDef[]>([]);
  const [feedback, setFeedback] = useState<Feedback>({
    general: "",
    columns: {},
  });

  const { fetchAPIWithToken } = useClient();

  const { isPending: isExporting, mutate: exportMutate } = useMutation<
    ExportMutationResult,
    Error,
    ExportMutationVariables
  >({
    mutationFn: async ({ tableName, columnIds }) => {
      const response = await fetchAPIWithToken(`/api/silver/export`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ tableName, columnIds }),
      });

      if (!response.ok) {
        throw new Error(`Failed to export silver data: ${response.statusText}`);
      }

      await downloadBlob(response);

      return { success: true };
    },
  });

  const addSelectedColumn = useCallback((newColumn: SilverColumnDef) => {
    setSelectedColumns((prev) => [...prev, newColumn]);
  }, []);

  const removeSelectedColumn = useCallback(
    (columnToRemove: SilverColumnDef) => {
      setSelectedColumns((prev) =>
        prev.filter((column) => column.id !== columnToRemove.id),
      );

      setFeedback((prev) => {
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [columnToRemove.id]: _, ...rest } = prev.columns;
        return { ...prev, columns: rest };
      });
    },
    [],
  );

  const handleExport = useCallback(() => {
    if (selectedColumns.length === 0) {
      return;
    }

    const tableName = selectedColumns[0]?.input_table;
    const columnIds = selectedColumns.map((column) => column.id);

    if (tableName) {
      exportMutate({ tableName, columnIds });
    }
  }, [selectedColumns, exportMutate]);

  const useColumnData = (
    column: SilverColumnDef,
  ): UseQueryResult<string[], Error> => {
    return useQuery({
      queryKey: ["silverColumnData", column.id],
      queryFn: async () => {
        const response = await fetchAPIWithToken(
          `/api/silver/columns/${column.id}/data?limit=50`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          },
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch column data for column ${column.output_column}`,
          );
        }

        const { data } = (await response.json()) as {
          data: Record<PropertyKey, unknown>[];
        };

        return data.map((data) => data[column.output_column]);
      },
    });
  };

  const value: DataBuilderContextType = {
    selectedColumns,
    addSelectedColumn,
    removeSelectedColumn,
    feedback,
    setFeedback,
    handleExport,
    isExporting,
    useColumnData,
  };

  return (
    <DataBuilderContext.Provider value={value}>
      {children}
    </DataBuilderContext.Provider>
  );
};

export const useDataBuilder = (): DataBuilderContextType => {
  const context = useContext(DataBuilderContext);
  if (context === undefined) {
    throw new Error("useDataBuilder must be used within a DataBuilderProvider");
  }
  return context;
};
