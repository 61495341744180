import { useState } from "react";

import { useClient } from "@hooks/use-client";

import { ActionIcon, Affix, rem, Stack, Title, Tooltip } from "@mantine/core";

import { Form } from "@components/Home/Form";
import { Recommendations } from "@components/Home/Recommendations";
import { BiArrowToRight } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  const { fetchAPIWithToken } = useClient();
  const [loading, setLoading] = useState(false);

  const createThread = async (starter: string) => {
    setLoading(true);

    try {
      const response = await fetchAPIWithToken(`/api/threads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ msg: starter }),
      });
      const { threadId } = await response.json();

      navigate(`/thread/${threadId}`);
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  };

  return (
    <>
      <Affix position={{ top: rem(30), left: rem(30) }}>
        <Tooltip label="Show previous threads" position="right" offset={8}>
          <ActionIcon
            color="gray"
            variant={"subtle"}
            size={"md"}
            onClick={() => navigate({ hash: "menu" })}
          >
            <BiArrowToRight style={{ width: rem(18) }} />
          </ActionIcon>
        </Tooltip>
      </Affix>
      <Stack w={750} py={175} h={"100vh"} align="center" gap={"xl"}>
        <Stack gap={0}>
          <Title order={1} mb={12}>
            Welcome
          </Title>
          <Form w={550} createThread={createThread} loading={loading} />
        </Stack>
        <Recommendations createThread={createThread} loading={loading} />
      </Stack>
    </>
  );
};
