import { type ElementRef, forwardRef } from "react";

import {
  Flex,
  type FlexProps,
  Group,
  type GroupProps,
  Stack,
} from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";

import { Recommendation } from "./Recommendation";

import { type ContextType, useClient } from "@hooks/use-client";
import { useHover } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

const RecommendationSchema = z.object({
  starter: z.string(),
  explanation: z.string(),
  type: z.string(),
});

type Recommendation = z.infer<typeof RecommendationSchema>;

const MotionGroup = motion.create(
  forwardRef<ElementRef<"div">, GroupProps>((props, ref) => (
    <Group ref={ref} {...props} />
  )),
);

const MotionFlex = motion.create(
  forwardRef<ElementRef<"div">, FlexProps>((props, ref) => (
    <Flex ref={ref} {...props} />
  )),
);

const getNewRecommendations = async (
  fetchAPIWithToken: ContextType["fetchAPIWithToken"],
) => {
  const response = await fetchAPIWithToken(
    `/api/recommendations?count=3&type=kpi and reporting`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    },
  );

  const { data, error } = z
    .object({
      recommendations: RecommendationSchema.array(),
    })
    .safeParse(await response.json());

  if (error) {
    console.error("Unable to parse recommendations");
    console.error(error);
    return [];
  }

  return data.recommendations;
};

export const Recommendations = ({
  createThread,
  loading,
}: {
  createThread: (starter: string) => Promise<void>;
  loading: boolean;
}) => {
  const { fetchAPIWithToken } = useClient();
  const { hovered, ref } = useHover();

  const { data: currentRecommendations } = useQuery<Recommendation[], Error>({
    queryKey: ["recommendations", fetchAPIWithToken],
    queryFn: () => getNewRecommendations(fetchAPIWithToken),
    refetchInterval: hovered || loading ? false : 5000,
  });

  return (
    <Stack ref={ref} mt={24}>
      <AnimatePresence>
        <MotionGroup
          key={JSON.stringify(currentRecommendations)}
          align="stretch"
          initial="hidden"
          animate="show"
          exit="hidden"
          variants={{
            hidden: { opacity: 0 },
            show: {
              opacity: 1,
              transition: {
                staggerChildren: 0.1,
                when: "beforeChildren",
              },
            },
          }}
        >
          {Array.isArray(currentRecommendations)
            ? currentRecommendations.map((rec) => (
                <MotionFlex
                  key={rec.starter}
                  flex={1}
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    show: { opacity: 1, y: 0 },
                  }}
                  transition={{
                    type: "tween",
                    ease: "easeInOut",
                    duration: 0.3,
                  }}
                >
                  <Recommendation
                    createThread={createThread}
                    starter={rec.starter}
                    explanation={rec.explanation}
                    type={rec.type}
                  />
                </MotionFlex>
              ))
            : null}
        </MotionGroup>
      </AnimatePresence>
    </Stack>
  );
};
