import { ShowQueryHandlerError } from "@components/Results/Errors/ShowQueryHandlerError";
import { ShowSqlGenerationSqlTimeoutError } from "@components/Results/Errors/ShowSqlGenerationTimeoutError";
import { FeedbackModal } from "@components/Results/FeedbackModal";

import { useResults } from "@components/Results/ResultsContext";
import { Table } from "@components/Results/Table";
import type { GoldViewDef } from "@components/Results/utils";
import { VerifyResultsDrawer } from "@components/Results/VerifyResultsDrawer";
import { useClient } from "@hooks/use-client";
import { Page } from "@layout/Page";
import { Badge, Button, Group, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  QueryHandlingError,
  SQLGenerationTimeout,
} from "@mm/shared/schemas/text2sql/errors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import {
  BiAnalyse,
  BiCheckCircle,
  BiCheckShield,
  BiExport,
} from "react-icons/bi";

interface FinalGoldViewMutationVariables {
  lastSuccessGoldView: GoldViewDef;
}

interface FinalGoldViewMutationResult {
  success: boolean;
}

interface ExportMutationVariables {
  goldView: GoldViewDef;
}

interface ExportMutationResult {
  success: boolean;
}

export const PENDING_FINAL = "PENDING_FINAL";
export const PENDING_DRAFT = "PENDING_DRAFT";

export const getGoldViewDetailsQueryKey = (threadId: number) => [
  "goldViewDetails",
  threadId,
];

export const getLastSuccessGoldViewQueryKey = (threadId: number) => [
  "lastSuccessGoldViewDetails",
  threadId,
];

/**
 * Downloads a blob from a Response object as a file.
 *
 * This is required because it's not possible to trigger a browser
 * download from a POST request.
 *
 * We are creating a DOM node and simulating a click in order to trigger
 * the download of a blob that we previously stored from the request.
 * @param res The response from the webserver
 */
const downloadBlob = async (res: Response) => {
  const contentDisposition = res.headers.get("Content-Disposition");
  let filename = "download.csv";

  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/i);
    if (filenameMatch && filenameMatch[1]) {
      filename = filenameMatch[1];
    }
  }

  const blob = await res.blob();
  const fileURL = URL.createObjectURL(blob);
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.download = filename;
  fileLink.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(fileURL);
  }, 100);
};

const useFinalizeView = (threadId: number) => {
  const queryClient = useQueryClient();
  const { fetchAPIWithToken } = useClient();

  return useMutation<
    FinalGoldViewMutationResult,
    Error,
    FinalGoldViewMutationVariables
  >({
    mutationFn: async ({ lastSuccessGoldView }) => {
      const response = await fetchAPIWithToken(
        `/api/flow/${lastSuccessGoldView.thread_id}/finalize`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ goldViewId: lastSuccessGoldView.id }),
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to export data for gold view: ${response.statusText}`,
        );
      }

      return { success: true };
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getGoldViewDetailsQueryKey(Number(threadId)),
        }),
        queryClient.invalidateQueries({
          queryKey: getLastSuccessGoldViewQueryKey(Number(threadId)),
        }),
      ]);
    },
  });
};

const useExportView = () => {
  const { fetchAPIWithToken } = useClient();

  return useMutation<ExportMutationResult, Error, ExportMutationVariables>({
    mutationFn: async ({ goldView }) => {
      const response = await fetchAPIWithToken(
        `/api/gold/views/${goldView.id}/export`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        },
      );

      if (!response.ok) {
        throw new Error(
          `Failed to export data for gold view: ${response.statusText}`,
        );
      }

      await downloadBlob(response);

      return { success: true };
    },
  });
};

export const ResultsContent = () => {
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { lastSuccessGoldView, goldView, thread } = useResults();
  const { isPending: isFinalizing, mutate: finalizeGoldViewMutate } =
    useFinalizeView(thread.id);

  const finalizeGoldView = useCallback(() => {
    if (!lastSuccessGoldView) {
      return;
    }

    finalizeGoldViewMutate({ lastSuccessGoldView });
  }, [lastSuccessGoldView, finalizeGoldViewMutate]);

  const { isPending: isExporting, mutate: exportMutate } = useExportView();

  const handleExport = useCallback(() => {
    if (!goldView) {
      return;
    }

    exportMutate({ goldView });
  }, [goldView, exportMutate]);

  const isStatusPending =
    goldView.status === PENDING_DRAFT ||
    goldView.status == PENDING_FINAL ||
    isFinalizing;

  const isFinal = goldView.status == "FINAL";
  const isPreview = !isFinal;

  const leftSection = (
    <Group gap={"xs"}>
      <Badge size="sm" variant="light" color={isPreview ? "gray" : undefined}>
        {isPreview ? "Draft" : "Final"}
      </Badge>
      <Tooltip
        w={300}
        multiline
        withArrow
        label={
          isPreview ? (
            <Text size="sm">
              <Text span fw={"bold"}>
                DRAFT MODE:
              </Text>{" "}
              Results based on data sample. Focus on structure and relevance of
              columns rather than exact values. You can still improve or
              finalize these results.
            </Text>
          ) : (
            <Text size="sm">
              <Text span fw={"bold"}>
                FINALIZED RESULTS:
              </Text>{" "}
              Results based on full dataset. Ready for use in reports and
              decision-making.
            </Text>
          )
        }
      >
        <Text size="xs" c={"blue"} style={{ cursor: "pointer" }}>
          What does it mean?
        </Text>
      </Tooltip>
    </Group>
  );

  const rightSection = (
    <Group gap={"xs"} ta={"right"}>
      <Button
        size="xs"
        variant="light"
        leftSection={<BiCheckShield size={14} />}
        onClick={openDrawer}
      >
        Results verification
      </Button>
      {isPreview && (
        <Button
          size="xs"
          variant="light"
          leftSection={<BiAnalyse size={14} />}
          disabled={isStatusPending}
          onClick={openModal}
        >
          Improve results
        </Button>
      )}
      {isPreview ? (
        <Button
          size="xs"
          leftSection={<BiCheckCircle size={14} />}
          disabled={isStatusPending}
          onClick={finalizeGoldView}
        >
          Finalize results
        </Button>
      ) : (
        <Button
          size="xs"
          leftSection={<BiExport size={14} />}
          disabled={isStatusPending || isExporting}
          onClick={handleExport}
          loading={isExporting}
        >
          Export
        </Button>
      )}
    </Group>
  );

  /*
   * We need to deal with the case where the first run is failing, i.e:
   * lastSuccessGoldView is null goldView.status is ERROR.XXXXX
   */
  if (!lastSuccessGoldView) {
    if (goldView.status == `ERROR.${QueryHandlingError.NAME}`) {
      return <ShowQueryHandlerError goldView={goldView} />;
    } else if (goldView.status == `ERROR.${SQLGenerationTimeout.NAME}`) {
      return <ShowSqlGenerationSqlTimeoutError goldView={goldView} />;
    }
  }

  return (
    <Page
      title="Insight Results"
      leftSection={leftSection}
      rightSection={rightSection}
    >
      {!!lastSuccessGoldView && <Table />}
      <FeedbackModal opened={openedModal} close={closeModal} />
      <VerifyResultsDrawer opened={openedDrawer} close={closeDrawer} />
    </Page>
  );
};
